import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EProductType, IProduct } from 'src/app/models/product.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-manage-product-dialog',
  templateUrl: './manage-product-dialog.component.html',
  styleUrl: './manage-product-dialog.component.scss',
})
export class ManageProductDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;

  @Input() editMode = false;
  @Input() productToEdit: IProduct = null;
  @Output() onSaveProduct = new EventEmitter<IProduct>();
  @Output() closeDialog = new EventEmitter<boolean>();

  public EProductType = EProductType;

  public dragEvent = false;
  public environment = environment;
  public product = {} as IProduct;

  constructor(private toastrService: ToastrService) {}

  async ngOnInit() {
    if (this.editMode) {
      this.product = cloneDeep(this.productToEdit);
    }
  }

  public saveProduct(): void {
    if (
      !this.product.title ||
      !this.product.image ||
      !this.product.price ||
      !this.product.description ||
      !this.product.type
    ) {
      this.toastrService.error('Please add all product information');
      return;
    }

    // removes the image from the product if it hasn't changed to avoid overwriting in backend
    if (this.editMode && this.productToEdit.image === this.product.image) {
      delete this.product.image;
    }

    this.onSaveProduct.emit(this.product);
    this.closeDialog.emit(true);
  }
  public onCloseDialog(): void {
    this.product = null;
    this.closeDialog.emit(true);
  }

  public getProductTypes(): EProductType[] {
    return Object.values(EProductType);
  }

  public onSelectProductType(productType: EProductType): void {
    this.product.type = productType;
  }

  public triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  public onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.handleFile(file);
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragEvent = true;
  }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragEvent = false;
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragEvent = false;
    const file = event.dataTransfer?.files[0];
    if (file) this.handleFile(file);
  }

  private handleFile(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const base64String = reader.result as string;
      this.product.image = base64String;
    };
    reader.readAsDataURL(file);
  }

  public async convertUrlToFile(url: string, fileName: string): Promise<File> {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new File([blob], fileName, { type: blob.type });
      });
  }
}
