<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl gap-5 max-lg:flex-col">
    <div class="relative flex flex-1 flex-col gap-4 bg-lightGrayish p-5 lg:mb-5 lg:gap-5">
      <app-text-content-box [headline]="'42 Shop'" [headerIcon]="'🛍️'" class="lg:pb-2"></app-text-content-box>
      @if (currentUser?.isAdmin) {
        <button
          (click)="openAddProductDialog()"
          type="button"
          class="absolute right-5 flex h-11 w-40 items-center justify-center gap-2 border-black bg-almostBlack p-2 font-semibold text-sunflowerGold"
          data-cy="add-product-btn"
        >
          <p>Add</p>
          <span> 🛒</span>
        </button>
      }

      <app-article-card
        class="mt-4"
        [products]="products"
        [currentUser]="currentUser"
        (articleBought)="getLatestTransactions()"
        (onDeleteProduct)="onDeleteProduct($event)"
        (onProductEdit)="onProductHasChangedProduct()"
      ></app-article-card>
    </div>

    <div class="mb-5 flex h-full w-96 flex-col gap-4 bg-lightGrayish p-5 max-lg:w-full lg:gap-5">
      <app-text-content-box [headline]="'Payment History'" [headerIcon]="'⌛'" class="lg:pb-2"></app-text-content-box>
      <div class="mt-4 flex max-h-96 flex-col gap-3 overflow-y-auto">
        @if (paymentHistory.length === 0) {
          <span>There is no payment history</span>
        }
        @for (history of paymentHistory; track history) {
          <div class="flex text-sm font-medium max-lg:text-xs">
            <span class="w-20 border-r-2 border-veryFaintBlack text-veryFaintBlack">{{
              history.transactionTime | date: 'dd.MM.yyyy'
            }}</span>
            <div class="overflow-hidden text-ellipsis whitespace-nowrap pl-4">
              <span
                [title]="history?.product?.title || 'Product not found. It may have been deleted'"
                class="truncate"
                >{{ history?.product?.title || 'Product deleted' }}</span
              >
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
@if (showAddProductDialog) {
  <app-manage-product-dialog
    (onSaveProduct)="onAddNewProduct($event)"
    (closeDialog)="showAddProductDialog = false"
  ></app-manage-product-dialog>
}
