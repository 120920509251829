import { EClothingSize, EShoeSize } from '../enum/size.enum';

export interface IProduct {
  _id?: string;
  title: string;
  price: number;
  description: string;
  size?: EClothingSize | EShoeSize;
  type: EProductType;
  image: string;
  productDescription: string;
}

export enum EProductType {
  CLOTHING = 'Clothing',
  FOOTWEAR = 'Footwear',
  HARDWARE = 'Hardware',
  VOUCHER = 'Voucher',
  TIME = 'Time',
  EQUIPMENT = 'Equipment',
  OTHER = 'Other',
}
