<div class="min-w-screen fixed inset-0 left-0 top-0 z-50 flex h-screen w-full">
  <div class="absolute inset-0 bg-black opacity-30"></div>
  <div
    class="relative m-auto w-[28rem] gap-4 bg-lightGrayish p-5 shadow-lg min_w_1640:w-[36rem]"
    data-cy="product-info-card"
  >
    <div class="flex justify-center">
      <img class="max-h-[30rem]" src="{{ product.image }}" alt="product img" />
    </div>
    <div class="flex flex-col gap-2 font-medium">
      <div class="flex items-center justify-between pt-4 max-lg:text-sm">
        <span>{{ product.title }}</span>
        <span class="flex items-center gap-1">
          {{ product.price }} Coins
          <img class="flex h-4" src="../../../assets/img/coin.png" alt="coin" />
        </span>
      </div>
      <span class="flex max-h-44 overflow-y-auto text-sm font-medium leading-[1.5] text-veryFaintBlack max-lg:text-xs">
        {{ product.description }}
      </span>
      @if (product.type === EProductType.CLOTHING) {
        <div class="pt-2 max-lg:text-sm">
          <span>Size</span>
          <div class="flex gap-2 pt-2">
            @for (size of getClothingSizes(); track size) {
              @if (size !== EClothingSize[0]) {
                <span
                  (click)="onSelectSize(size)"
                  class="flex w-8 cursor-pointer items-center justify-center p-1"
                  data-cy="product-clothing-size"
                  [ngClass]="{
                    'bg-sunflowerGold text-white': product.size === size,
                    'bg-white text-sunflowerGold': product.size !== size,
                  }"
                >
                  {{ size }}
                </span>
              }
            }
          </div>
        </div>
      }
      @if (product.type === EProductType.FOOTWEAR) {
        <div>
          <div
            class="relative w-36 cursor-pointer max-smC:w-[6.5rem] max-smC:text-sm lg:w-40"
            (focusout)="showSizeDropdown = false"
            tabindex="0"
          >
            <span class="flex py-2">Size</span>
            <div (click)="onToggleDropDown()" class="text-mainGreen flex h-12 items-center bg-white px-3 py-2">
              <span class="truncate pr-2" data-cy="product-shoe-size">{{ product.size || 'Größe wählen' }}</span>
              <fa-icon
                [icon]="dropDownIcon"
                class="absolute right-2 -mb-1"
                [ngClass]="{ '-mb-0 rotate-180': showSizeDropdown }"
              >
              </fa-icon>
            </div>

            @if (showSizeDropdown) {
              <ul
                [ngClass]="{ 'opacity-0': !showSizeDropdown, 'opacity-100': showSizeDropdown }"
                class="absolute right-0 z-50 -mt-3 flex h-32 w-full flex-col gap-2 overflow-auto bg-white p-2 opacity-0 transition-all duration-500"
              >
                @for (size of getShoeSizes(); track size) {
                  <li
                    (click)="onSelectSize(size)"
                    class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
                    data-cy="product-shoe-size-select"
                  >
                    <span class="truncate">{{ size }}</span>
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      }
    </div>
    <div class="flex justify-between pt-8">
      <div
        (click)="onCloseDialog()"
        class="mb-2 flex w-32 cursor-pointer items-center justify-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
      >
        Cancel
      </div>
      <div
        (click)="onBuyProduct()"
        class="flex h-11 w-32 cursor-pointer items-center justify-center border border-black bg-almostBlack p-5 text-sm text-sunflowerGold hover:bg-black"
        data-cy="buy-product-btn"
      >
        Buy
      </div>
    </div>
  </div>
</div>
