import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IEmployee } from 'src/app/models/employee.model';
import { IProduct } from 'src/app/models/product.model';
import { ITransaction } from 'src/app/models/transaction.model';
import { AuthService } from 'src/app/services/auth.service';
import { ProductService } from 'src/app/services/product.service';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
})
export class ShopComponent {
  private subscription = new Subscription();
  public paymentHistory = [] as ITransaction[];
  public products = [] as IProduct[];

  public showAddProductDialog = false;
  public currentUser: IEmployee;

  constructor(
    private transactionService: TransactionService,
    private productService: ProductService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
    this.currentUser = this.authService.getCurrentEmployee();
    this.getLatestTransactions();
    this.getAllProducts();
  }

  public getLatestTransactions(): void {
    this.subscription.add(
      this.transactionService.getLatestTransactions().subscribe({
        next: (res) => {
          this.paymentHistory = res.DATA;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private getAllProducts(): void {
    this.subscription.add(
      this.productService.getAllProducts().subscribe({
        next: (res) => {
          this.products = res.DATA.sort((a: IProduct, b: IProduct) => a.price - b.price);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public onProductHasChangedProduct(): void {
    this.getAllProducts();
  }

  public onDeleteProduct(productId: string): void {
    this.products = this.products.filter((prod) => prod._id !== productId);
  }

  public openAddProductDialog(): void {
    this.showAddProductDialog = true;
  }

  public onAddNewProduct(product: IProduct): void {
    this.subscription.add(
      this.productService.addNewProduct(product).subscribe({
        next: (res) => {
          this.toastr.success(res.MESSAGE);
          this.showAddProductDialog = false;
          this.getAllProducts();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
