import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Product } from '@piwikpro/tracking-base-library';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EClothingSize } from 'src/app/enum/size.enum';
import { IConfirmData } from 'src/app/models/confirm-data';
import { IEmployee } from 'src/app/models/employee.model';
import { EProductType, IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';
import { ProductService } from 'src/app/services/product.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrl: './article-card.component.scss',
})
export class ArticleCardComponent implements OnDestroy {
  @Input() public products: IProduct[];
  @Input() public currentUser: IEmployee;
  @Output() public articleBought = new EventEmitter();
  @Output() public onDeleteProduct = new EventEmitter<string>();
  @Output() public onProductEdit = new EventEmitter();
  public EProductType = EProductType;
  public EClothingSize = EClothingSize;

  public showShopDialog = false;
  public selectedProduct: IProduct = null;

  private subscription = new Subscription();

  public environment = environment;
  public deletionInfo = {} as IConfirmData;
  public showDeleteDialog = false;
  public showAddProductDialog = false;

  private productToDelete: IProduct = null;
  public productToEdit: IProduct = null;

  constructor(
    private transactionService: TransactionService,
    private toastrService: ToastrService,
    private helperService: HelperService,
    private productService: ProductService,
  ) {}

  public onEditProductClick(product: IProduct): void {
    this.productToEdit = product;
    this.showAddProductDialog = true;
  }

  public editProduct(product: IProduct): void {
    this.subscription.add(
      this.productService.updateProduct(product).subscribe({
        next: () => {
          this.toastrService.success('Product was updated successfully');
          this.helperService.userCoinsChanged$.next(true);
          this.productToEdit = null;
          this.onProductEdit.emit();
        },
        error: (err) => {
          this.toastrService.error(err.error.MESSAGE);
          this.productToEdit = null;
        },
      }),
    );
  }

  public onDeleteProductClick(product: IProduct): void {
    this.deletionInfo.headline = 'Delete this Product?';
    this.deletionInfo.text = `Are you sure to delete the Product ${product.title}?`;
    this.deletionInfo.image = 'delete';
    this.showDeleteDialog = true;
    this.productToDelete = product;
  }

  public deleteProduct(): void {
    this.subscription.add(
      this.productService.deleteProduct(this.productToDelete._id).subscribe({
        next: () => {
          this.toastrService.success('Product was deleted successfully');
          this.onDeleteProduct.emit(this.productToDelete._id);
          this.productToDelete = null;
        },
        error: (err) => {
          this.toastrService.error(err.error.MESSAGE);
          this.productToDelete = null;
        },
      }),
    );
  }

  public onOpenShopDialog(product: IProduct, size?: EClothingSize): void {
    this.selectedProduct = product;
    this.selectedProduct.size = size;
    this.showShopDialog = true;
  }

  public closeShopDialog(): void {
    this.showShopDialog = false;
  }

  public onBuyProduct(product: IProduct): void {
    this.subscription.add(
      this.transactionService.createTransaction(product).subscribe({
        next: () => {
          this.toastrService.success('Congrats! Product was added successfully');
          this.helperService.userCoinsChanged$.next(true);
          this.articleBought.emit();
        },
        error: (err) => {
          this.toastrService.error(err.error.MESSAGE);
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
