import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RevenueType } from 'src/app/enum/revenue.enum';
import { GoalDataResponse, RevenuesResponse } from 'src/app/models/response.model';
import { IRevenue, RevenueData } from 'src/app/models/revenue.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { RevenueService } from 'src/app/services/revenue.service';
import { Chart, registerables } from 'chart.js';
import { Month } from '../../models/common.model';
@Component({
  selector: 'revenues',
  templateUrl: './revenues.component.html',
  styleUrls: ['./revenues.component.scss'],
})
export class RevenuesComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  public monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  //Zu viele Variablen
  private subscription = new Subscription();

  private revenuesArray: IRevenue[] = [];

  private revenuesRealsByMonth: IRevenue[] = [];
  private currentYearBestGoals: IRevenue[] = [];
  private currentYearWorstGoals: IRevenue[] = [];
  public valuesYearHTML: { real: number; goal: number; name: string }[] = [];

  public revenueMonth: string;
  public revenueValue: number;

  public revenues = [
    { type: 0, value: 'GOAL_WORST' },
    { type: 1, value: 'GOAL_BEST' },
    { type: 2, value: 'REAL' },
  ];

  public selectedRevenueType = RevenueType.REAL;

  private currentQuarter: IRevenue[] = [];
  private currentQuarterReals: IRevenue[] = [];
  private currentQuarterBestGoals: IRevenue[] = [];
  private currentQuarterWorstGoals: IRevenue[] = [];

  public valuesQuarterHTML: { real: number; goal: number; name: string }[] = [];

  public reRenderCharts = 0;
  public isPhoneSize: boolean;

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    private revenueService: RevenueService,
    private helperService: HelperService,
  ) {}

  ngOnInit(): void {
    Chart.register(...registerables);
    this.allRevenuesCurrentYear();
    this.getRealsRevenuesCurrentYear();
  }

  public onSelectMonth(month: string) {
    this.revenueMonth = month;
  }

  private allRevenuesCurrentYear(): void {
    this.subscription.add(
      this.revenueService.getAllRevenuesCurrentYear().subscribe({
        next: (response: RevenuesResponse) => {
          this.revenuesArray = response.DATA;
          this.setCurrentQuarterRevenues(this.revenuesArray);
          // this.deleteRevenues(this.revenuesArray[0]._id);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private setCurrentQuarterRevenues(revenuesArray: IRevenue[]): void {
    this.assignCurrentQuarterRevenues(revenuesArray);
    this.sortCurrentQuarterByMonth();
    this.splitRevenuesGoalsAndReals();
  }

  private getCurrentQuarterIndex(): { currentQuarterStart: number; currentQuarterEnd: number } {
    const currentQuarterIndex = Math.ceil((new Date().getMonth() + 1) / 3);
    const currentQuarterStart = (currentQuarterIndex - 1) * 3 + 1;
    const currentQuarterEnd = currentQuarterIndex * 3;
    return { currentQuarterStart, currentQuarterEnd };
  }

  private assignCurrentQuarterRevenues(revenuesArray: IRevenue[]): void {
    const currentYear = new Date().getFullYear().toString();
    let { currentQuarterStart, currentQuarterEnd } = this.getCurrentQuarterIndex();
    for (let month = currentQuarterStart; month <= currentQuarterEnd; month++) {
      let revenueForMonth = revenuesArray.find((revenue) => revenue.month === month);
      if (!revenueForMonth) {
        let monthWithoutValue = { month: month, year: currentYear } as IRevenue;
        revenuesArray.push(monthWithoutValue);
      }
    }
    this.currentQuarter = revenuesArray.filter(
      (revenue) => revenue.month >= currentQuarterStart && revenue.month <= currentQuarterEnd,
    );
  }

  private sortCurrentQuarterByMonth(): void {
    this.currentQuarter.sort((a, b) => a.month - b.month);
  }

  private splitRevenuesGoalsAndReals(): void {
    this.currentQuarterBestGoals = [];
    this.currentQuarterWorstGoals = [];
    this.currentQuarterReals = [];
    this.currentQuarter.forEach((item) => {
      const itemType = this.getEnumValueByKey(RevenueType, item.type);
      if (itemType === RevenueType.GOAL_BEST) {
        this.currentQuarterBestGoals.push(item);
      }
      if (itemType === RevenueType.GOAL_WORST) {
        this.currentQuarterWorstGoals.push(item);
      } else if (itemType === RevenueType.REAL) {
        this.currentQuarterReals.push(item);
      }
    });
  }

  public createNewRevenueTarget(): ActiveToast<Error> | void {
    if (this.revenueMonth === 'Month') {
      return this.toastr.error('Choose a month');
    }
    if (this.revenueValue === undefined || this.revenueValue === null) {
      return this.toastr.error('Choose a value');
    }
    const monthIndex = this.monthNames.indexOf(this.revenueMonth);
    if (monthIndex === -1) {
      return this.toastr.error('Invalid month selected');
    }
    const selectedMonthEnum = monthIndex + 1;
    this.addCurrentRevenue(selectedMonthEnum);
  }

  private addCurrentRevenue(selectedMonthEnum: Month): void {
    const selectedRevenueString = this.getEnumKeyByValue(RevenueType, +this.selectedRevenueType);
    this.subscription.add(
      this.revenueService.addNewRevenue(selectedMonthEnum, selectedRevenueString, this.revenueValue).subscribe({
        next: () => {
          this.helperService.updateQuarterSums.next();
          this.toastr.success(
            `New revenue in ${this.revenueMonth} ${selectedRevenueString} ${this.revenueValue.toString()} € `,
          );
          this.revenueValue = null;
          this.valuesQuarterHTML = [];
          this.allRevenuesCurrentYear();
          this.getRealsRevenuesCurrentYear();
          this.reRender();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private getRealsRevenuesCurrentYear(): void {
    this.subscription.add(
      this.revenueService.getAllRevenuesRealCurrentYear().subscribe({
        next: (response: RevenuesResponse) => {
          this.revenuesRealsByMonth = response.DATA;
          this.getGoalsRevenuesCurrentYear();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private getGoalsRevenuesCurrentYear(): void {
    this.subscription.add(
      this.revenueService.getAllRevenuesGoalsCurrentYear().subscribe({
        next: (res: GoalDataResponse) => {
          this.currentYearBestGoals = res.DATA.GOAL_BEST;
          this.currentYearWorstGoals = res.DATA.GOAL_WORST;
          this.quarterBarChartInit();
          this.yearBarChartInit();
          this.observePhoneSize();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private prepareChartData(
    monthsArray: IRevenue[],
    goalsBestArray: IRevenue[],
    goalsWorstArray: IRevenue[],
    realsArray: IRevenue[],
    chartType: 'quarter' | 'year',
  ) {
    const totalMonths = chartType === 'quarter' ? 3 : 12;
    const monthNames = this.initMonthNames(chartType, monthsArray);
    const { goalWorstValues, goalBestValues, realValues } = this.assignValues(
      totalMonths,
      monthNames,
      goalsBestArray,
      goalsWorstArray,
      realsArray,
    );

    const valuesHTML: RevenueData[] = this.createValuesHTML(
      totalMonths,
      monthNames,
      realValues,
      goalWorstValues,
      goalBestValues,
    );

    if (chartType === 'quarter') {
      this.valuesQuarterHTML = valuesHTML;
    } else {
      this.valuesYearHTML = valuesHTML;
    }
    return { monthNames, realValues, goalBestValues, goalWorstValues };
  }

  createValuesHTML(
    totalMonths: number,
    monthNames: string[],
    realValues: number[],
    goalWorstValues: number[],
    goalBestValues: number[],
  ): RevenueData[] {
    const valuesHTML = [];
    for (let i = 0; i < totalMonths; i++) {
      let { proofedRealValue, proofedGoalBestValues, proofedGoalWorstValues } = this.processMonthDataForChart(
        realValues,
        goalWorstValues,
        goalBestValues,
        i,
      );
      valuesHTML.push({
        real: proofedRealValue,
        goal_best: proofedGoalBestValues,
        goal_worst: proofedGoalWorstValues,
        name: monthNames[i],
      });
    }
    return valuesHTML;
  }

  assignValues(
    totalMonths: number,
    monthNames: string[],
    goalsBestArray: IRevenue[],
    goalsWorstArray: IRevenue[],
    realsArray: IRevenue[],
  ) {
    const goalBestValues = Array(totalMonths).fill(0);
    const goalWorstValues = Array(totalMonths).fill(0);
    const realValues = Array(totalMonths).fill(0);
    for (let i = 0; i < totalMonths; i++) {
      const goalWorstItem = goalsWorstArray.find((item) => this.monthNames[item.month - 1] === monthNames[i]);
      const goalBestItem = goalsBestArray.find((item) => this.monthNames[item.month - 1] === monthNames[i]);
      const realItem = realsArray.find((item) => this.monthNames[item.month - 1] === monthNames[i]);
      goalWorstValues[i] = goalWorstItem?.value;
      goalBestValues[i] = goalBestItem?.value;
      realValues[i] = realItem?.value;
    }

    return { goalWorstValues, goalBestValues, realValues };
  }

  initMonthNames(chartType: 'quarter' | 'year', monthsArray: IRevenue[]): string[] {
    return chartType === 'quarter' ? this.getAndSortMonthNamesForChart(monthsArray) : this.monthNames;
  }

  getAndSortMonthNamesForChart(monthsArray: IRevenue[]): string[] {
    let monthNames = this.getMonthNameForQuarterChart(monthsArray);
    return monthNames.sort((a, b) => monthNames.indexOf(a) - monthNames.indexOf(b));
  }

  /**
   * Retrieves the month name for a quarter chart based on the given month array.
   * This function iterates through the given array, extracting the month name from each item,
   * and ensuring no duplicate month names are included.
   * @param {IRevenue[]} monthsArray - The array of month revenues.
   * @returns {string[]} - The array of month names.
   */
  getMonthNameForQuarterChart(monthsArray: IRevenue[]): string[] {
    const monthNames: string[] = [];
    for (let item of monthsArray) {
      if (typeof item !== 'string' && item.month !== undefined) {
        const monthName = this.monthNames[item.month - 1];
        if (monthNames.indexOf(monthName) === -1) {
          monthNames.push(monthName);
        }
      }
    }
    return monthNames;
  }

  processMonthDataForChart(realValues: number[], goalWorstValues: number[], goalBestValues: number[], i: number) {
    let { thousandsRealValue, thousandsGoalBestValues, thousandsGoalWorstValues } = this.getRealAndGoalsByThousands(
      realValues,
      goalWorstValues,
      goalBestValues,
      i,
    );
    let proofedRealValue = this.handleNaNValue(thousandsRealValue);
    let proofedGoalBestValues = this.handleNaNValue(thousandsGoalBestValues);
    let proofedGoalWorstValues = this.handleNaNValue(thousandsGoalWorstValues);
    return { proofedRealValue, proofedGoalBestValues, proofedGoalWorstValues };
  }

  getRealAndGoalsByThousands(realValues: number[], goalWorstValues: number[], goalBestValues: number[], i: number) {
    let thousandsRealValue = this.helperService.sumToFixed(realValues[i], 'chart');
    let thousandsGoalBestValues = this.helperService.sumToFixed(goalBestValues[i], 'chart');
    let thousandsGoalWorstValues = this.helperService.sumToFixed(goalWorstValues[i], 'chart');

    return { thousandsRealValue, thousandsGoalWorstValues, thousandsGoalBestValues };
  }

  handleNaNValue(value: number | string): number | string {
    if (value === 'NaNk') {
      value = 0 + 'k';
      return value;
    } else if (value === 'NaNM') {
      value = 0 + 'M';
      return value;
    } else {
      return value;
    }
  }

  getAverage(goalValues: number[], realValues: number[]): number {
    const numericGoalValues = goalValues.map(Number);
    const average = numericGoalValues.reduce((a, b) => a + b, 0) / realValues.length;
    return average;
  }

  quarterBarChartInit(): void {
    let { monthNames, realValues, goalWorstValues, goalBestValues } = this.prepareChartData(
      this.currentQuarter,
      this.currentQuarterBestGoals,
      this.currentQuarterWorstGoals,
      this.currentQuarterReals,
      'quarter',
    );

    const quarterBarChartConfig: any = {
      type: 'line',
      data: {
        labels: monthNames.map((name: string) => [name.substring(0, 0)]),
        datasets: [
          {
            label: 'REAL',
            data: realValues,
            backgroundColor: 'rgb(230 174 72)',
            borderColor: 'black',
            borderWidth: 3,
          },
          {
            label: 'GOAL_WORST',
            data: goalWorstValues,
            backgroundColor: 'red',
            borderColor: 'rgb(255 201 102)',
            borderWidth: 3,
          },
          {
            label: 'GOAL_BEST',
            data: goalBestValues,
            backgroundColor: 'green',
            borderColor: 'rgb(255 201 102)',
            borderWidth: 3,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: '',
          },
        },
        scales: {
          y: {
            stacked: false,
            ticks: {
              color: '#000',
              beginAtZero: true,
              callback: function (value: number): string {
                if (value > 1) {
                  return value / 1000 + '.000k €';
                } else {
                  return '0.0k €';
                }
              },
              font: {
                size: this.isPhoneSize ? 8 : 16,
                family: 'poppins',
                weight: '500',
              },
            },
            grid: {
              display: false,
            },
          },
          x: {
            stacked: false,
            grid: {
              display: false,
              borderColor: 'transparent',
            },
          },
        },
      },
    };

    const chartCanvas = document.getElementById('quarterBarChart') as HTMLCanvasElement;
    const existingChart = Chart.getChart(chartCanvas);
    if (existingChart) {
      existingChart.destroy();
    }
    new Chart(chartCanvas, quarterBarChartConfig);
  }

  yearBarChartInit(): void {
    let { monthNames, realValues, goalWorstValues, goalBestValues } = this.prepareChartData(
      this.revenuesArray,
      this.currentYearBestGoals,
      this.currentYearWorstGoals,
      this.revenuesRealsByMonth,
      'year',
    );

    const yearBarChartConfig: any = {
      type: 'line',
      data: {
        labels: monthNames.map((name) => name.substring(0, 0)),
        datasets: [
          {
            label: 'REAL',
            data: realValues,
            backgroundColor: 'rgb(230 174 72)',
            borderColor: 'black',
            borderWidth: 3,
          },
          {
            label: 'GOAL_WORST',
            data: goalWorstValues,
            backgroundColor: 'red',
            borderColor: 'rgb(255 201 102)',
            borderWidth: 3,
          },
          {
            label: 'GOAL_BEST',
            data: goalBestValues,
            backgroundColor: 'green',
            borderColor: 'rgb(255 201 102)',
            borderWidth: 3,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: '',
          },
        },
        scales: {
          y: {
            stacked: false,
            ticks: {
              color: '#000',
              beginAtZero: true,
              callback: function (value: number): string {
                if (value > 1) {
                  return value / 1000 + '.000k €';
                } else {
                  return '0.0k €';
                }
              },
              font: {
                size: this.isPhoneSize ? 8 : 16,
                family: 'poppins',
                weight: '500',
              },
              chartAreaBorder: {
                borderColor: 'transparent',
              },
            },
            grid: {
              display: false,
            },
          },
          x: {
            stacked: true,
            grid: {
              display: false,
              borderColor: 'transparent',
            },
          },
        },
      },
    };

    const chartCanvas = document.getElementById('yearBarChart') as HTMLCanvasElement;
    const existingChart = Chart.getChart(chartCanvas);
    if (existingChart) {
      existingChart.destroy();
    }
    new Chart(chartCanvas, yearBarChartConfig);
  }
  private deleteRevenues(id: string): void {
    this.subscription.add(
      this.revenueService.deleteRevenue(id).subscribe({
        next: () => {
          this.helperService.updateQuarterSums.next();
          this.toastr.success('Revenue deleted');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public onSelectRevenue(revenue) {
    this.selectedRevenueType = revenue.type;
  }

  /**
   * function accesses the provided enum object and returns the numeric value that matches the specified key.
   * @param {Object} enumObj - The enum object to access.
   * @param {string} key - The key whose corresponding value is to be found.
   * @returns {number} The numeric value that matches the given key in the enum object.
   */
  public getEnumValueByKey(enumObj: any, key): number {
    return enumObj[key];
  }

  /**
   * This function searches through the provided enum object to find the key that matches the given numeric value.
   * @param {Object} enumObj - The enum object to be searched.
   * @param {number} value - The numeric value whose key is to be found.
   * @returns {string} The key as a string that matches the given value in the enum object, or `undefined` if no matching key is found.
   */
  public getEnumKeyByValue(enumObj: any, value: number): string {
    return Object.keys(enumObj).find((key) => enumObj[key] === value || '');
  }

  /**
   * Re-render the charts after adding new revenue.
   */
  public reRender(): void {
    this.reRenderCharts++;
  }

  private observePhoneSize(): void {
    this.helperService.isPhoneSize$.subscribe((isPhoneSize) => {
      this.isPhoneSize = isPhoneSize;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
