<div
  class="min-w-screen animated fadeIn faster fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
  id="modal-id"
>
  <div class="absolute inset-0 z-0 bg-black opacity-80"></div>
  <div
    class="relative m-auto flex w-full max-w-[600px] flex-col items-center rounded-xl bg-lightGrayish p-14 shadow-lg"
  >
    <div class="flex justify-start sm:w-full">
      <h1 class="text-xl">{{ info.headline }}</h1>
    </div>
    @if (authService.getCurrentEmployee().isAdmin && info.type == 'add') {
      <div class="mt-6 sm:w-full">
        <div class="flex items-center gap-2">
          <label class="text-sm font-medium text-gray-900">For another employee</label>
          <input
            type="checkbox"
            (change)="anotherEmployee($event.target)"
            class="h-5 w-9 appearance-none rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          />
        </div>
        @if (forAnotherEmployee) {
          <app-dropdown
            class="mt-6"
            [elements]="employees"
            placeholder="Select employee"
            [isEmployeeDropdown]="true"
            (selectElement)="onSelectEmployee($event)"
          ></app-dropdown>
        }
      </div>
    }

    <form class="mt-6 w-full">
      <div class="mb-6 flex flex-col items-center justify-between gap-5 sm:flex-row sm:items-end lg:grid-cols-2">
        <div class="form__group field">
          <label class="mb-2 block text-sm font-medium text-gray-900">Start Date</label>
          <input
            type="text"
            mwlFlatpickr
            class="block w-[200px] border border-gray-300 p-2.5 text-sm text-gray-900"
            placeholder="Start date"
            (change)="onSelectStartDate($event)"
            [ngModel]="selectedLeave.startDate"
            [ngModelOptions]="{ standalone: true }"
            required
            [altInput]="true"
            [convertModelValue]="true"
            [options]="helperService.datePickerOptions"
            [minDate]="minStartDate"
          />
        </div>
        <div class="form__group field">
          <label class="mb-2 block text-sm font-medium text-gray-900">End Date</label>
          <input
            type="text"
            mwlFlatpickr
            class="block w-[200px] border border-gray-300 p-2.5 text-sm text-gray-900"
            placeholder="End date"
            (change)="onSelectEndDate($event)"
            [ngModel]="selectedLeave.endDate"
            [ngModelOptions]="{ standalone: true }"
            required
            [altInput]="true"
            [convertModelValue]="true"
            [options]="helperService.datePickerOptions"
            [minDate]="minEndDate"
          />
        </div>
      </div>

      <div class="relative flex w-full flex-col-reverse items-center justify-center gap-2 sm:flex-row sm:justify-end">
        @if (info.type === 'edit') {
          <button
            (click)="onDelete()"
            class="flex h-9 items-center border border-gray-200 bg-white px-5 py-2.5 text-center text-sm font-medium tracking-wider text-red-500 shadow-sm hover:bg-gray-100 hover:shadow-lg focus:outline-none focus:ring-4"
          >
            Delete
          </button>
        }

        <div class="flex w-full items-center justify-between gap-5">
          <button
            (click)="onClose()"
            class="flex h-9 items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg"
          >
            Close
          </button>

          <button
            (click)="onSubmit()"
            class="flex h-9 items-center justify-center border border-black bg-almostBlack px-5 py-2.5 text-center text-sm font-medium tracking-wider text-sunflowerGold shadow-sm hover:bg-black hover:shadow-lg focus:outline-none focus:ring-4 sm:w-auto"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
