<div class="grid justify-between gap-4 md:grid-cols-2" data-cy="products-container">
  @if (products.length === 0) {
    <span>There are no products in the shop</span>
  }
  @for (product of products; track product) {
    <div class="flex-col" data-cy="product-element">
      <div data-cy="product-image-container" class="group relative flex h-72 items-center overflow-hidden">
        <img
          (click)="onOpenShopDialog(product)"
          class="h-[inherit] w-full cursor-pointer"
          [ngClass]="{ 'object-cover group-hover:translate-y-0': product.type }"
          src="{{ product.image }}"
        />
        @if (currentUser?.isAdmin || product.type === EProductType.CLOTHING) {
          <div
            [ngClass]="product.type === EProductType.CLOTHING ? 'justify-between' : 'justify-end'"
            class="absolute bottom-0 flex w-full translate-y-full transform bg-white p-2 font-medium text-veryFaintBlack transition-transform duration-200 group-hover:translate-y-0 max-lg:text-sm"
          >
            @if (product.type === EProductType.CLOTHING) {
              <div class="flex gap-4">
                <span (click)="onOpenShopDialog(product, EClothingSize.XS)" class="cursor-pointer hover:text-black"
                  >XS</span
                >
                <span (click)="onOpenShopDialog(product, EClothingSize.S)" class="cursor-pointer hover:text-black"
                  >S</span
                >
                <span (click)="onOpenShopDialog(product, EClothingSize.M)" class="cursor-pointer hover:text-black"
                  >M</span
                >
                <span (click)="onOpenShopDialog(product, EClothingSize.L)" class="cursor-pointer hover:text-black"
                  >L</span
                >
                <span (click)="onOpenShopDialog(product, EClothingSize.XL)" class="cursor-pointer hover:text-black"
                  >XL</span
                >
              </div>
            }
            @if (currentUser?.isAdmin) {
              <div class="flex gap-4 text-veryFaintBlack">
                <button data-cy="edit-product-btn" (click)="onEditProductClick(product)">
                  <img class="h-4" src="assets/img/icons/edit.png" alt="edit" />
                </button>
                <button data-cy="delete-product-btn" (click)="onDeleteProductClick(product)">
                  <img class="h-4" src="assets/img/icons/delete.png" alt="delete" />
                </button>
              </div>
            }
          </div>
        }
      </div>
      <div class="flex flex-col gap-1 pb-8 pt-2">
        <span data-cy="product-title" class="font-medium max-lg:text-sm">{{ product.title }}</span>
        <span
          class="clamp-2 -mt-1 max-h-[2.4em] min-h-[2.4em] overflow-hidden overflow-ellipsis text-xs font-medium text-veryFaintBlack max-lg:text-[10px]"
          >{{ product.description }}</span
        >
        <span class="flex items-center gap-2 text-sm font-medium max-lg:text-xs" data-cy="product-price"
          >{{ product.price }} Coins <img class="flex h-4" src="../../../assets/img/coin.png"
        /></span>
      </div>
    </div>
  }
</div>
@if (showShopDialog) {
  <app-shop-dialog
    [product]="selectedProduct"
    (closeDialog)="closeShopDialog()"
    (acceptDialog)="onBuyProduct($event)"
  ></app-shop-dialog>
}
@if (showDeleteDialog) {
  <app-confirm-dialog
    (closeDialog)="showDeleteDialog = false"
    (saveChanges)="deleteProduct()"
    [data]="deletionInfo"
  ></app-confirm-dialog>
}
@if (showAddProductDialog) {
  <app-manage-product-dialog
    [productToEdit]="productToEdit"
    [editMode]="true"
    (onSaveProduct)="editProduct($event)"
    (closeDialog)="showAddProductDialog = false"
  ></app-manage-product-dialog>
}
