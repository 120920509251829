import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProduct } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  public addNewProduct(product: IProduct): Observable<any> {
    return this.http.post(environment.baseUrl + '/products', product);
  }

  public getAllProducts(): Observable<any> {
    return this.http.get(environment.baseUrl + '/products/all');
  }

  public updateProduct(product: IProduct): Observable<any> {
    return this.http.put(environment.baseUrl + '/products/' + product._id, product);
  }
  public deleteProduct(productId: string): Observable<any> {
    return this.http.delete(environment.baseUrl + '/products/' + productId);
  }
}
